import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { bsc } from 'wagmi/chains';
import { publicProvider } from "wagmi/providers/public";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Admin from "./routes/Admin";
import VestingRoute from "./routes/Vesting";

const chains = [bsc];
const projectId = '7218dac6a58a53a0a4df6bbfe42a235f';

const { publicClient } = configureChains(chains, [
  w3mProvider({ projectId }),
  publicProvider()
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

/**
 * Address of the admin address that manages contracts
 */
export const OwnerAdmin = "0x124dF913F0E78857f336101f31e61b88737eC23D";

/**
 * Set the network we use in a single place
 */
//export const usedNetwork = BSC;

/**
 * Set the address of ACCSale in a single place
 */
export const ACCSaleAddress = "0xC8173116A997e77267B119Ffcb17543896Ff88D9";
export const ACCTokenAddress = "0xc513caec0d339535c1eda50d8e2ca4bc431ec493";

export const VestingAddress = [
  "0x4F85aB5985D8Bd61693986a28FBdf145c279478e",
  "0x57721D1F2a3D5cEeEA7c8D7587e5F33f50e78F65",
  "0x4FaD43F634003055241EF55CA2fEC99278b0b950",
  "0xac015e412598f692db4ffBEF1d75a21478D4DfC0",
];

export const Vesting = {
  cold: VestingAddress[0],
  marketing: VestingAddress[1],
  holders: VestingAddress[2],
  liquidity: VestingAddress[3],
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<VestingRoute />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/vesting" element={<VestingRoute />} />
        </Routes>
      </BrowserRouter>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
