//import { useContractFunction } from "@usedapp/core";
//import { Contract } from "ethers";
import { FC } from "react";
import { ACCSaleAddress } from "..";
import ABI from "../util/SaleABI.json";
import { Btn } from "./Button";
import { useContractWrite } from "wagmi";

const WithdrawButton: FC = () => {
  /*const { send } = useContractFunction(
    new Contract(ACCSaleAddress, ABI),
    "withdraw"
  );*/

  const { write } = useContractWrite({
    address: ACCSaleAddress,
    abi: ABI,
    functionName: "withdraw",
  });

  return (
    <Btn
      onClick={() =>
        write({ args: [
          "0xba94736e891e8419c1a8ddd32ea7b1b7b7489cee",
          "0xba94736e891e8419c1a8ddd32ea7b1b7b7489cee",
          "0xdAC619FE2A121E3f27B72806cE03AE06F0493Ea4"
      ]})
      }
    >
      Withdraw
    </Btn>
  );
};

export { WithdrawButton };
