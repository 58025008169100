import { JsonRpcProvider } from "@ethersproject/providers";
import { BigNumber, Contract } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { useAccount, useContractWrite } from "wagmi";
import { ACCSaleAddress } from "..";
import Connect from "../components/Connect";
import { DisconnectButton } from "../components/DisconnectButton";
import BgImage from "../img/bg.png";
import SaleABI from "../util/SaleABI.json";

async function getAllVestingClaims(
  account: string,
): Promise<Claim[]> {
  const data: Claim[] = [];

  const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/", 56);
  const contract = new Contract(ACCSaleAddress, SaleABI, provider);

  let claimId = 0;
  while (true) {
    try {
      const claim = await contract.vestingClaims(account, claimId);
      data.push(processVestingClaim(claim));
    } catch (e) {
      break;
    }
    claimId++;
  }

  return data;
}

type RawClaim = [BigNumber, BigNumber, BigNumber];
type Claim = {
  fullAmount: string;
  claimableAmount: string;
  claimedAmount: string;
  startTime: string;
  endTime: string;
};

function processVestingClaim(claim: RawClaim): Claim {
  const [_fullAmount, _claimedAmount, _startTimestamp] = claim;
  const _endTimestamp = _startTimestamp.add(24 * 60 * 60 * 30 * 12); // 12 months

  const _claimableAmount = calculateClaimableVestAmount(
    _fullAmount,
    _startTimestamp,
    _endTimestamp
  ).sub(_claimedAmount);

  return {
    fullAmount: formatEther(_fullAmount).slice(0, 8),
    claimableAmount: formatEther(_claimableAmount).slice(0, 8),
    claimedAmount: formatEther(_claimedAmount).slice(0, 8),
    startTime: new Date(_startTimestamp.toNumber() * 1000).toLocaleDateString(),
    endTime: new Date(_endTimestamp.toNumber() * 1000).toLocaleDateString(),
  };
}

function calculateClaimableVestAmount(
  amount: BigNumber,
  start: BigNumber,
  end: BigNumber
): BigNumber {
  const now = BigNumber.from(Date.now()).div(1000);

  if (now.gte(end)) {
    return amount;
  } else if (start.gte(now)) {
    return BigNumber.from(0);
  }

  const passed = now.sub(start);
  const duration = end.sub(start);
  const claimable = amount.mul(passed).div(duration);

  return claimable;
}

export default function VestingRoute() {
  const { address } = useAccount();
  const [vestingClaims, setVestingClaims] = useState<Claim[]>([]);
  const [selectedClaim, setSelectedClaim] = useState<number>(0);
  const [lang, setLang] = useState<"pl" | "en">("en");

  useEffect(() => {
    if (!address) {
      setVestingClaims([]);
      return;
    }
    getAllVestingClaims(address).then(setVestingClaims);
  }, [address]);

  return (
    <div className="w-screen min-h-screen py-4 flex flex-col justify-evenly items-center">
      <div className="fixed top-0 bottom-0 left-0 right-0 bg-[#0563fd44]">
        <img src={BgImage} className="w-full h-full object-cover" alt="" />
      </div>
      <article className="w-[90%] md:w-1/4 md:min-w-[500px] h-2/3 min-h-[650px] bg-black text-white shadow-2xl drop-shadow-2xl rounded-2xl flex flex-col items-center justify-evenly px-6 md:px-8 pt-6 md:pt-10 pb-6">
        <img
          alt="logo ecat"
          src="/logo.png"
          className="h-[30px] md:h-[50px] mt-10 md:mb-6 md:mt-0"
        />
        <h1 className="text-2xl md:text-3xl font-bold text-center">
          Aderlo Cashback Coin - Vesting
        </h1>

        <p className="mt-2">
          {lang === "pl"
            ? "80% zakupionych przez Ciebie tokenów ACC będzie uwalniać się z czasem. W ciągu 12 miesięcy od zakupu tokenów dostępna będzie coraz większa kwota, aż po upływie roku można będzie wypłacić całość. W każdej chwili możesz wypłacić część tokenów, którą już udało się uwolnić."
            : "80% of your purchased ACC tokens will be released over the time period of 12 months starting from the time of purchase. At any time you can withdraw the amount of tokens that were already released."}
        </p>

        <p className="mt-2">
          {lang === "pl"
            ? "Wybierz, którym zakupem chcesz zarządzać:"
            : "Choose which purchase you want to manage:"}
        </p>

        <div className="mb-auto flex flex-row my-2 gap-4 flex-wrap items-center">
          {vestingClaims.map((claim, idx) => (
            <div
              key={idx}
              className={`text-white font-bold w-10 h-10 flex flex-row items-center justify-center cursor-pointer\
              transition-all duration-300\
              ${selectedClaim === idx
                  ? "bg-blue-500"
                  : "bg-gray-700 shadow-lg drop-shadow-lg"
                }`}
              onClick={() => setSelectedClaim(idx)}
            >
              {idx + 1}
            </div>
          ))}
        </div>

        <div className="h-full flex flex-col justify-center items-center mb-auto">
          {vestingClaims[selectedClaim] && (
            <ClaimElement
              idx={selectedClaim}
              claim={vestingClaims[selectedClaim]}
              lang={lang}
            />
          )}
        </div>

        <div
          className="absolute top-4 left-4 h-10 flex flex-row gap-2 justify-center items-center cursor-pointer select-none"
          onClick={() => setLang((old) => (old === "pl" ? "en" : "pl"))}
        >
          <p className="uppercase w-9 h-9 flex flex-col items-center justify-center border-gray-300 border-2 rounded-full">
            {lang === "pl" ? "en" : "pl"}
          </p>
        </div>

        {address ? <DisconnectButton /> : <Connect />}
      </article>
    </div>
  );
}

function ClaimElement({
  claim,
  idx,
  lang,
}: {
  claim: Claim;
  idx: number;
  lang: "pl" | "en";
}) {
  const { write } = useContractWrite({
    address: ACCSaleAddress,
    abi: SaleABI,
    functionName: "withdrawVestedAmount",
  });

  return (
    <div className="flex flex-col items-center justify-center w-full h-full mt-4">
      <div className="flex flex-row flex-wrap w-full justify-between gap-x-8">
        <p>
          {lang === "pl" ? "data zakupu" : "date of purchase"}:{" "}
          {claim.startTime}
        </p>
        <p>
          {lang === "pl" ? "zakończenie" : "end date"}: {claim.endTime}
        </p>
      </div>
      <div className="flex flex-row flex-wrap justify-evenly mt-4 items-center w-full gap-4">
        <div className="flex flex-col items-center justify-center">
          <p className="text-gray-600">
            {lang === "pl" ? "RAZEM W VESTINGU" : "VESTED"}
          </p>
          <p className="font-bold text-xl">{claim.fullAmount} ACC</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="uppercase text-gray-600">
            {lang === "pl" ? "wypłacono już" : "already withdrawn"}
          </p>
          <p className="font-bold text-xl">{claim.claimedAmount} ACC</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="uppercase text-gray-600">
            {lang === "pl" ? "można wypłacić" : "can be withdrawn"}
          </p>
          <p className="font-bold text-xl">{claim.claimableAmount} ACC</p>
        </div>
      </div>
      <button
        onClick={() => write({ args: [idx] })}
        className="py-3 px-4 bg-gray-300 mb-4 mt-8 mx-8 rounded-2xl hover:scale-95 shadow-lg transition duration-200"
      >
        {lang === "pl" ? "Wypłać" : "Withdraw"}{" "}
        <span className="font-bold">{claim.claimableAmount} ACC</span>
      </button>
    </div>
  );
}
