import { useDisconnect } from 'wagmi'
import { FC } from "react";
import { Btn } from "./Button";

const DisconnectButton: FC = () => {
  const { disconnect } = useDisconnect()

  return (
    <div className="absolute top-4 right-4">
      <Btn
        onClick={() => {
          localStorage.clear();
          disconnect();
        }}
        className="!bg-black !text-white !px-3 !py-1 !text-base border-orange-500 border-2 order-last"
      >
        Disconnect
      </Btn>
    </div>
  );
};

export { DisconnectButton };
