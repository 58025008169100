import { formatEther } from "ethers/lib/utils";
import { useCallback } from "react";
import { ACCTokenAddress } from "..";
import VestingABI from "../util/VestingABI.json";
import { useAccount, useContractReads, useContractWrite } from "wagmi";

export default function VestingWallet({
  name,
  contractAddress,
}: {
  name: string;
  contractAddress: string;
}) {
  const { write } = useContractWrite({
    address: contractAddress as `0x${string}`,
    abi: VestingABI,
    functionName: "release",
  });

  const withdraw = useCallback(() => {
    write({ args: [ACCTokenAddress], });
  }, [write]);

  const { address } = useAccount();

  const vestingContract = { address: contractAddress as `0x${string}`, abi: VestingABI as any, };

  const { data, status } = useContractReads({
    contracts: [
      {
        ...vestingContract,
        functionName: "beneficiary",
      },
      {
        ...vestingContract,
        functionName: "duration",
      },
      {
        ...vestingContract,
        functionName: "released",
        args: [ACCTokenAddress]
      },
      {
        ...vestingContract,
        functionName: "start",
      },
      {
        ...vestingContract,
        functionName: "vestedAmount",
        args: [ACCTokenAddress, Math.floor(Date.now() / 1000)]
      },
    ],
  });

  if (status !== "success" || !data) return null;

  const [beneficiaryRaw, duratoinRaw, releasedRaw, startRaw, vestedRaw] = data;

  const beneficiary = (beneficiaryRaw.result && typeof beneficiaryRaw.result === "string") ? (beneficiaryRaw as any).result : "error";
  const duration = (duratoinRaw.result && typeof duratoinRaw.result === "bigint") ? Number((duratoinRaw as any).result) : 0;
  const released = (releasedRaw.result && typeof releasedRaw.result === "bigint") ? ((releasedRaw as any).result as bigint) : BigInt(0);
  const start = (startRaw.result && typeof startRaw.result === "bigint") ? Number((startRaw as any).result) : 0;
  const vested = (vestedRaw.result && typeof vestedRaw.result === "bigint") ? ((vestedRaw as any).result as bigint) : BigInt(0);

  const start_str = start
    ? new Date(start * 1000).toLocaleDateString()
    : "error";
  const duration_str =
    start && duration
      ? new Date((start + duration) * 1000).toLocaleDateString()
      : "error";
  const released_str = formatEther(released);
  const vested_str = formatEther(vested);

  return (
    <div className="text-white mb-12">
      <h1 className="font-bold text-lg">{name}</h1>
      <p>start vestingu: {start_str}</p>
      <p>zakończenie vestingu: {duration_str}</p>
      <p>wypłacona kwota: {released_str} ACC</p>
      <p>możliwe do wypłacenia: {vested_str} ACC</p>
      <p>kwota będzie wypłacona na: {beneficiary}</p>
      <button
        onClick={withdraw}
        className="my-1 p-1 bg-gray-700 disabled:line-through"
        disabled={!address}
      >
        wypłać
      </button>
    </div>
  );
}
