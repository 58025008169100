import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { parseEther } from "viem";
import { ACCSaleAddress, OwnerAdmin, Vesting } from "..";
import Connect from "../components/Connect";
import { DisconnectButton } from "../components/DisconnectButton";
import VestingWallet from "../components/VestingWalletPanel";
import { WithdrawButton } from "../components/WithdrawButton";
import SaleABI from "../util/SaleABI.json";

import {
  useAccount,
  useContractRead,
  useContractWrite
} from "wagmi";

export default function Admin() {
  const { address } = useAccount();

  return (
    <div className="my-12 flex flex-col justify-center items-center w-full md:w-2/3 min-h-screen px-6 text-white">
      <div className="my-4">{address ? <DisconnectButton /> : <Connect />}</div>
      <div className="mt-4 mb-8">
        <GiveACCAdmin />
      </div>
      <div className="my-4">{address === OwnerAdmin && <WithdrawButton />}</div>
      <div className="my-4">
        <SetPrice />
      </div>
      <div className="my-4">
        <VestingWallet
          name="Aderlo Cold Wallet"
          contractAddress={Vesting.cold}
        />
        <VestingWallet
          name="Marketing Wallet"
          contractAddress={Vesting.marketing}
        />
        <VestingWallet
          name="Holder's Rewards"
          contractAddress={Vesting.holders}
        />
        <VestingWallet
          name="Liquidity Pool"
          contractAddress={Vesting.liquidity}
        />
      </div>
    </div>
  );
}

function SetPrice() {
  const { address } = useAccount();
  const [price, setPrice] = useState(0);

  const { data: tokensPerBNBRaw } = useContractRead({
    address: ACCSaleAddress,
    abi: SaleABI,
    functionName: "tokensPerETH",
  });

  const { write } = useContractWrite({
    address: ACCSaleAddress,
    abi: SaleABI,
    functionName: "setTokenPrice",
  });

  let tokensPerBNBDyn = 0;
  /*if (tokensPerBNBRaw && tokensPerBNBRaw.value && tokensPerBNBRaw.value.length >= 1 && typeof tokensPerBNBRaw.value[0] === "object") {
    tokensPerBNBDyn = tokensPerBNBRaw.value[0].toNumber();
  }*/

  tokensPerBNBDyn = Number(tokensPerBNBRaw);

  const tokensPerBNB = useMemo(() => tokensPerBNBDyn, [tokensPerBNBDyn]);
  useEffect(() => {
    setPrice(tokensPerBNB);
  }, [tokensPerBNB]);

  const isOwner = address === OwnerAdmin;

  return (
    <div className="w-full">
      <h1 className="font-bold text-lg">Change price</h1>
      <div className="flex flex-row gap-2 items-center">
        <label>
          <span className="font-bold mr-2">1 BNB = </span>
          <input
            type="number"
            className="w-24 text-black"
            value={price}
            onChange={(e) => setPrice(parseInt(e.target.value, 10))}
            disabled={!isOwner}
          />
          <span className="font-bold ml-2">ACC</span>
        </label>
        <button
          onClick={() => {
            if (price && typeof price === "number") write({ args: [price] });
          }}
          className="my-1 p-1 bg-gray-700 disabled:line-through"
          disabled={!isOwner}
        >
          zmień
        </button>
      </div>
    </div>
  );
}

function GiveACCAdmin() {
  const [amount, setAmount] = useState("0");
  const [beneficiary, setBeneficiary] = useState("");

  const { write } = useContractWrite({
    address: ACCSaleAddress,
    abi: SaleABI,
    functionName: "sendOutsidePurchase",
  });

  const onSubmit = useCallback(() => {
    write({ args: [parseEther(amount), beneficiary] });
  }, [amount, beneficiary, write]);

  const amountNum = useMemo(() => {
    try {
      return parseEther(amount);
    } catch (e) {
      return BigInt(0);
    }
  }, [amount]);

  const { address } = useAccount();
  const isOwner = address === OwnerAdmin;

  return (
    <div className="flex flex-col gap-2">
      <Container>
        <label htmlFor="Amount">Amount in ACC</label>
        <input
          id="Amount"
          type="number"
          value={amount}
          onChange={(e) => {
            e.preventDefault();
            setAmount(e.target.value);
          }}
          className="text-black"
        />
      </Container>
      <Container>
        <label htmlFor="Beneficiary">Beneficiary (address)</label>
        <input
          id="Beneficiary"
          type="text"
          value={beneficiary}
          onChange={(e) => {
            e.preventDefault();
            setBeneficiary(e.target.value);
          }}
          className="text-black"
        />
      </Container>
      <div>
        <button
          onClick={onSubmit}
          className="bg-white text-black py-1 px-4 rounded-lg"
          disabled={!isOwner}
        >
          <p className={`font-bold text-lg ${isOwner ? "" : "line-through"}`}>
            Click to send ACC
          </p>
          <p>
            ~
            {((Number(amountNum) / 10) * 2) /
              (parseEther("1") as any).toString()}{" "}
            ACC will be sent
          </p>
          <p>
            ~
            {((Number(amountNum) / 10) * 8) /
              (parseEther("1") as any).toString()}{" "}
            ACC will be vested
          </p>
        </button>
      </div>
    </div>
  );
}

const Container: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="flex flex-row gap-2">{children}</div>
);
