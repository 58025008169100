import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from "react";

const Button: FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = (props) => <button {...{ 
    ...props,
    className: `text-orange-500 px-6 md:px-8 py-2 md:py-3 text-lg md:text-xl font-bold rounded-xl text-white shadow-md hover:shadow-xl hover:drop-shadow-xl hover:scale-95 transition-all duration-300 ${props.className || ""}`, 
}} />;

export { Button as Btn };
